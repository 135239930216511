<template>
  <div class="container home-slider-main" id="home-slider">
    <b-carousel
      v-if="slides.length != 0"
      id="carousel-1"
      :interval="slide_interval"
      controls
      no-hover-pause
      indicators
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333"
      @input="onSlideChange"
    >
      <div v-for="(slide_data, index) in slides">
        <b-carousel-slide
          :img-src="slide_data.web_slider"
          @error="handleMoveNext()"
          v-if="slide_data.file_type == 'Image'"
        ></b-carousel-slide>
        <b-carousel-slide v-if="slide_data.file_type == 'Video'">
          <video
            :id="'slide-' + index"
            @ended="index === slide_index && handleMoveNext()"
            @error="index === slide_index && handleMoveNext()"
            muted
            autoplay
            class="video-cover"
          >
            <source :src="slide_data.web_slider" type="video/mp4" />
          </video>
        </b-carousel-slide>
      </div>
    </b-carousel>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel2";

export default {
  name: "AppSlider",
  components: {
    carousel,
  },
  data() {
    return {
      userData: {},
      loader: false,
      errors: [],
      slides: [],
      showslider: false,
      slide_interval: 5000,
      slide_index: 0,
      intervals: null,
    };
  },
  mounted() {
    var token = localStorage.getItem("userData");
    this.userData = token;

    this.intervals = setInterval(() => {
      this.getSliderImages();
    }, 300000);
  },
  created() {
    this.getSliderImages();
  },
  beforeDestroy() {
    clearInterval(this.intervals);
  },
  methods: {
    getSliderImages() {
      this.errors = [];
      let method_type = "";
      let fetch_url = "";
      method_type = "get";
      fetch_url = process.env.VUE_APP_URL + "customer/page/getSliderImages";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.slides = data.slider;
          if (this.slides.length != 0) {
            this.showslider = true;
            if (data.slider[0].file_type === "Video") {
              this.onSlideChange(0);
              this.slide_interval = 60000000;
            }
          }
        });
    },
    onSlideChange(slideNumber) {
      this.slide_interval = 5000;
      if (this.slides[slideNumber].file_type == "Video") {
        const videoEl = document.querySelector(`#slide-${slideNumber}`);
        if (videoEl) {
          videoEl.currentTime = 0;
          videoEl.play();
        }
        this.slide_interval = 60000000;
      }
      this.slide_index = slideNumber;
    },
    handleMoveNext() {
      this.slide_interval = 1;
    },
  },
};
</script>
<style>
.home-slider-main {
  max-width: 55% !important;
}

.home-slider-main *:is(video, img, .carousel-item) {
  width: 100% !important;
  max-width: 100% !important;
  height: 480px !important;
}

.home-slider-main .carousel-caption {
  left: 0;
  right: 0;
  width: 100%;
}

@media screen and (max-width: 1280px) {
  .home-slider-main {
    max-width: 80% !important;
  }

  .home-slider-main *:is(video, img, .carousel-item) {
    height: 320px !important;
  }
}

@media screen and (max-width: 640px) {
  .home-slider-main {
    max-width: 95% !important;
  }

  .home-slider-main *:is(video, img, .carousel-item) {
    height: 240px !important;
  }
}
</style>
